import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import {graphql} from 'gatsby'
import TransferAcountImg from '../../assets/images/magic-sync/transfer-acount.webp'
import TransferAcountMImg from '../../assets/images/magic-sync/transfer-acount-m.webp'
import MagicSyncMImg from '../../assets/images/magic-sync/magic-sync-m.webp'
import SyncEasyMImg from '../../assets/images/magic-sync/sync-easy-m.webp'
import PrivateSecureMImg from '../../assets/images/magic-sync/private-secure-m.webp'
import { Link } from 'gatsby'

const MagicSync = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <SEO 
                postTitle='Magic Sync | BlueMail App' 
                postDescription='MagicSync is compatible with any email provider and allows you to backup, restore, and securely transfer your information between an a large number of devices.'
                postImage="/img/OG/og_image-magic-sync.png"
                postURL='features-functions/magicsync'
                postSEO
            />
            <Navbar />
            <div className='features-functions'>
              <div className='container-fluid magic-sync w-707 r'>
                <div className='container'>                        
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-xs-12'>
                          <h1><span>Magic</span>Sync</h1>
                          <p>No matter how many devices you have, BlueMail’s MagicSync feature makes syncing your accounts a breeze.</p>
                      </div>                          
                    </div>
                </div>
              </div>
              <div className='container-fluid w-991'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0'>
                    <img src={MagicSyncMImg} alt='Magic Sync' />
                  </div>
                </div>
              </div> 
              <div className='container-fluid sync-easy w-707 l'>
                <div className='container'>                        
                    <div className='row'>
                      <div className='col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12'>
                          <h2><span>Syncing</span> made easy</h2>
                          <p>MagicSync is compatible with any email provider and allows you to backup, restore, and securely transfer your information between an a large number of devices. All of your data is encrypted, so you can be totally confident that your sensitive information is safe. And updates you make to your BlueMail app settings will automatically apply to all linked devices.</p>
                      </div>                          
                    </div>
                </div>
              </div>
              <div className='container-fluid w-991'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0'>
                    <img src={SyncEasyMImg} alt='Syncing made easy' />
                  </div>
                </div>
              </div> 
              <div className='container-fluid'>
                <div className='container'>                        
                    <div className='row'>
                      <div className='col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center'>
                          <h2><span>Transfer</span> Account Settings</h2>
                          <p>To sync your email accounts between your active device and your new device, you just need to follow a few simple steps.</p>
                          <Link to="/blog/magicsync-settings-sync" className="default-btn">
                                Learn More
                          </Link>
                      </div>                          
                    </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12 col-md-12 col-xs-12 p-0 mb-100'>
                    <img className='d-992' src={TransferAcountImg} alt='Transfer Account Settings' />
                    <img className='w-991 mt-3 mb-5' src={TransferAcountMImg} alt='Transfer Account Settings mobile' />
                  </div>
                </div>
              </div>
              <div className='container-fluid private-secure mb-100 w-707 r'>
                <div className='container'>                        
                    <div className='row'>
                      <div className='col-lg-6 col-md-6 col-xs-12'>
                          <h2>Private and Secure</h2>
                          <p>With MagicSync, it’s a snap to sync your email accounts across all your devices. And most importantly, you can do so with the complete assurance that your account data is safe, private and secure. MagicSync is yet another reason why BlueMail is the best email app for multiple email accounts.</p>
                      </div>                          
                    </div>
                </div>
              </div>
              <div className='container-fluid w-991'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12 col-xs-12 mt-3 mb-5'>
                    <img src={PrivateSecureMImg} alt='Private and Secure' />
                  </div>
                </div>
              </div>                  
            </div>
            <Footer />
        </Layout>
    )
}

export default MagicSync

export const query = graphql`
query MagicSyncPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
